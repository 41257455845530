import {
  ShiftReadiness as ShiftReadiness,
  ProdOperation,
} from '@/models/operation.model';
import { Order, ShiftStatus } from '@/models/order.model';
import { HttpService } from './http.service';

export class ProductionService extends HttpService {
  private static _selectedDate = ''; //:string

  private async Production<T>(parameters: [string, string][]): Promise<T> {
    return await this.put<T, string>('/Production/', parameters, '');
  }

  async CheckOperation(
    selectedOperation: ProdOperation
  ): Promise<ProdOperation> {
    return await this.Production<ProdOperation>([
      ['action', 'checkoperation'],
      ['operationId', selectedOperation.Id],
    ]);
  }

  async FinishOperation(operationId: string): Promise<void> {
    await this.Production<string>([
      ['action', 'finishoperation'],
      ['operationId', operationId],
    ]);
  }

  async GetProductionList(
    targetShift: string,
    terminalId = '',
    doexport = false
  ): Promise<ProdOperation[]> {
    if (!terminalId) {
      terminalId = await this.getTerminalId();
    }
    await this.checkTokenAvailability();
    const parameters: [string, string][] = [
      ['action', 'getoperationsforshift'],
      ['terminalId', terminalId],
      ['targetShift', targetShift],
    ];
    if (doexport) {
      parameters.push(['export', 'true']);
    }
    return await this.Production<ProdOperation[]>(parameters);
  }

  async GetDateReadiness(targetShift: string): Promise<ShiftReadiness[]> {
    await this.checkTokenAvailability();
    return await this.Production<ShiftReadiness[]>([
      ['action', 'listshiftreadiness'],
      ['targetShift', targetShift],
    ]);
  }

  GetSelectedDate(): string {
    if (!ProductionService._selectedDate) {
      const today = new Date(Date.now());
      const months = today.getMonth() + 1;
      const days = today.getDate();
      const shift = ShiftStatus.getShiftList()[0].value;
      ProductionService._selectedDate =
        today.getFullYear() +
        '-' +
        (months < 10 ? '0' + months : months) +
        '-' +
        (days < 10 ? '0' + days : days) +
        shift;
    }
    return ProductionService._selectedDate;
  }

  async ReserveOperation(operationId: string): Promise<void> {
    await this.Production<string>([
      ['action', 'reserveoperation'],
      ['operationId', operationId],
      ['terminalId', await this.getTerminalId()],
    ]);
  }

  async GetOrders(orderIds: string[]): Promise<Order[]> {
    return await this.put<Order[], string[]>('/Orders/List', [], orderIds);
  }
}
